import VueRouter from 'vue-router';
import routes from './routes';
import swal from 'sweetalert2';


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// hooks
router.beforeEach((to, from, next) => {


  if (to.meta.requiresAuthentication) {
    if (localStorage.token) {
      next()
    } else {
      next("login")
    }

  } else {
    next()
  }

  if (to.meta.requiresAdmin) {
    if (localStorage.user_type == 1) {
      next()
    } else {
      //show alert
      swal.fire({
        title: `Unauthorized Access To ${to.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });

      next("home")
    }

  }

  if (to.meta.requiresAgent) {
    if (localStorage.user_type == 2) {
      next()
    } else {
      //show alert
      swal.fire({
        title: `Unauthorized Access To ${to.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });

      next("home")
    }
  }

  if (to.meta.requiresCustomer) {
    if (localStorage.user_type == 4) {
      next()
    } else {
      //show alert
      swal.fire({
        title: `Unauthorized Access To ${to.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });

      next("home")
    }
  }

  if (to.meta.requiresModel) {
    if (localStorage.user_type == 3) {
      next()
    } else {
      //show alert
      swal.fire({
        title: `Unauthorized Access To ${to.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });

      next("home")
    }
  }


}
)

export default router;

const api_url = "https://api.rosecoco.fun/api"

// define a mixin object
var datumMixin = {
  created: function () {
    // this.hello()
  },
  methods: {
    alertNotification(verticalAlign, horizontalAlign, color, message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color
      });
    },
    calculateAge(dob) {

      var dateOfBirth = new Date(dob);
      var currentDate = new Date();
      // Calculate the age
      var age = currentDate.getFullYear() - dateOfBirth.getFullYear();

      // Adjust the birthdate based on the current month and day
      if (
        currentDate.getMonth() < dateOfBirth.getMonth() ||
        (currentDate.getMonth() === dateOfBirth.getMonth() &&
          currentDate.getDate() < dateOfBirth.getDate())
      ) {
        age--;
      }

      return age;
    },
    getDisplayDate(date) {
      let year = date.substring(0, 4);
      let month = date.substring(5, 7);
      let day = date.substring(8, 10);
      let time = this.tConvert(date.substring(11));
      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      let compDate = new Date(year, month - 1, day); // month - 1 because January == 0
      let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
      if (compDate.getTime() == today.getTime()) {
        return "Today at " + time;
      } else if (diff <= 24 * 60 * 60 * 1000) {
        return "Yesterday at " + time;
      } else {
        return compDate.toDateString() + " at " + time; // or format it what ever way you want
      }
    },
    formatModelDetails(model)
    {
        let location_details = (model.location_details!='[]') ? JSON.parse(model.location_details) : null
        let personal_details = (model.personal_details!='[]') ? JSON.parse(model.personal_details) : null
        let payment_details = (model.payment_details!='[]') ? JSON.parse(model.payment_details) : null


        //2 . Prep it
        model.location_details = location_details
        model.personal_details = personal_details
        model.payment_details = payment_details

        if (//(model.payment_details)
        (model.personal_details)
        &&(model.location_details))
        {
            model.hasAllDetails = true
        }
        else
        {
            model.hasAllDetails = false

        }

        return model


    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    removeLocalStorageItems() {
      localStorage.clear();
    }
    
  }
}

export { api_url, datumMixin }

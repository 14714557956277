<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar :background-color="sidebarBackground" :short-title="$t('sidebar.shortTitle')" :title="$t('sidebar.title')">
      <template slot="links">
        <sidebar-item v-if="userType == 1" :link="{
          name: $t('sidebar.analytics'),
          icon: 'tim-icons icon-sound-wave',
          path: '/admin/analytics'
        }">
        </sidebar-item>
        <sidebar-item :link="{
          name: $t('sidebar.home'),
          icon: 'tim-icons icon-heart-2',
          path: '/home'
        }">
        </sidebar-item>

        <sidebar-item :link="{
          name: $t('sidebar.explore'),
          icon: 'tim-icons icon-zoom-split',
          path: '/explore'
        }">
        </sidebar-item>

        <sidebar-item :link="{
          name: $t('sidebar.locations'),
          icon: 'tim-icons icon-compass-05',
          path: '/locations'
        }">
        </sidebar-item>

        <!-- <sidebar-item v-if="userType == 4" :link="{
          name: $t('sidebar.requestBoard'),
          icon: 'tim-icons icon-video-66',
          path: '/live-feed'
        }">
        </sidebar-item> -->

        <sidebar-item v-if="userType" :link="{
          name: $t('sidebar.chat'),
          icon: 'tim-icons icon-chat-33',
          path: '/chat'
        }">
        </sidebar-item>
        <sidebar-item v-if="userType == 3" :link="{
          name: $t('sidebar.createContent'),
          icon: 'tim-icons icon-simple-add',
          path: '/create'
        }">
        </sidebar-item>
        <sidebar-item v-if="userType == 3" :link="{
          name: $t('sidebar.accountSettings'),
          icon: 'tim-icons icon-pencil',
          path: '/account/stars'
        }">
        </sidebar-item>

        <sidebar-item v-if="userType == 2" :link="{
          name: $t('sidebar.accountSettings'),
          icon: 'tim-icons icon-pencil',
          path: '/account/agents'
        }">
        </sidebar-item>
        <sidebar-item v-if="userType == 2" :link="{
          name: $t('sidebar.manageModels'),
          icon: 'tim-icons icon-single-02',
          path: '/agents/manageModels'
        }">
        </sidebar-item>

        <sidebar-item v-if="userType == 4" :link="{
          name: $t('sidebar.userprofile'),
          icon: 'tim-icons icon-pencil',
          path: '/account/subscribers'
        }">

        </sidebar-item>
        <sidebar-item v-if="userType == 1" :link="{
          name: $t('sidebar.adminstrationPage'),
          icon: 'tim-icons icon-tie-bow',
          path: '/admin/management'
        }">
        </sidebar-item>
        <sidebar-item v-if="userType == 3" :link="{
          name: $t('sidebar.modelAnalytics'),
          icon: 'tim-icons icon-sound-wave',
          path: '/model/statistics'
        }">
        </sidebar-item>
        <sidebar-item v-if="userType == 2" :link="{
          name: $t('sidebar.modelAnalytics'),
          icon: 'tim-icons icon-sound-wave',
          path: '/agent/statistics'
        }">
        </sidebar-item>






      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>

    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from './SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      sidebarBackground: 'primary', //vue|blue|orange|green|red|primary
      userType: 0
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(true);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
    this.userType = localStorage.getItem('user_type')
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[(_vm.userType == 1)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.analytics'),
        icon: 'tim-icons icon-sound-wave',
        path: '/admin/analytics'
      }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.home'),
        icon: 'tim-icons icon-heart-2',
        path: '/home'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.explore'),
        icon: 'tim-icons icon-zoom-split',
        path: '/explore'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.locations'),
        icon: 'tim-icons icon-compass-05',
        path: '/locations'
      }}}),(_vm.userType)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.chat'),
        icon: 'tim-icons icon-chat-33',
        path: '/chat'
      }}}):_vm._e(),(_vm.userType == 3)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.createContent'),
        icon: 'tim-icons icon-simple-add',
        path: '/create'
      }}}):_vm._e(),(_vm.userType == 3)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.accountSettings'),
        icon: 'tim-icons icon-pencil',
        path: '/account/stars'
      }}}):_vm._e(),(_vm.userType == 2)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.accountSettings'),
        icon: 'tim-icons icon-pencil',
        path: '/account/agents'
      }}}):_vm._e(),(_vm.userType == 2)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.manageModels'),
        icon: 'tim-icons icon-single-02',
        path: '/agents/manageModels'
      }}}):_vm._e(),(_vm.userType == 4)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.userprofile'),
        icon: 'tim-icons icon-pencil',
        path: '/account/subscribers'
      }}}):_vm._e(),(_vm.userType == 1)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.adminstrationPage'),
        icon: 'tim-icons icon-tie-bow',
        path: '/admin/management'
      }}}):_vm._e(),(_vm.userType == 3)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.modelAnalytics'),
        icon: 'tim-icons icon-sound-wave',
        path: '/model/statistics'
      }}}):_vm._e(),(_vm.userType == 2)?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.modelAnalytics'),
        icon: 'tim-icons icon-sound-wave',
        path: '/agent/statistics'
      }}}):_vm._e()],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    user_id :"",
    token:"",
    user_type:"",
    email:"",
    first_name:"",
    middle_name:"",
    last_name:"",
    agency_name:""
}
const actions = {
    actionName({commit}, data) {
        state.myStore.value = data;
    }
}

const mutations = {
  
    set(state, [variable, value]) {
        state[variable] = value
    },

}



export default new Vuex.Store({
    state,
    actions,
    mutations
})
import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Auth/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';




// Auth Components

const User = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Auth/UserProfile.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Auth/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Auth/Register.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Auth/Lock.vue');

// Home, Explore & Locations
const Home = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/Home.vue');
const Explore = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/Explore.vue');
const Locations = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/Locations.vue');
const LiveFeed = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/LiveFeed.vue');
const Chat = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/Chat.vue');
const ModelProfile = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/ModelProfile.vue');
const ModelProfileByUserId = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/ModelProfile.vue');



// Create Posts
const Create = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Stars/CreatePost.vue');

// Create Categories
const Categories = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Admin/Categories.vue');

// Account Settings Stars
const AccountSettingsStars = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/AccountSettings/stars/Stars.vue');

// Account Settings Agents
const AccountSettingsAgents = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/AccountSettings/agents/Agents.vue');

// Account Settings Subscribers
const AccountSettingsSubscribers = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/AccountSettings/subscribers/Subscribers.vue');

// Manage Models (Agents)
const ManageModels = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Agents/ManageModels.vue');

// Manage Models (Admin)
const ManageAllModels = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Admin/ManageAllModels.vue');

// Manage Models (Admin)
const ManageAgents = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Admin/ManageAgents.vue');

// Manage Models (Admin)
const CreateModel = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Agents/CreateModel.vue');

//Agent Profile
const AgentProfile = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/AgentProfile.vue');

//Story View 
const StoryFullView = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/StoryFullView.vue');

//Post Comments
const PostComments = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/PostComments.vue'); //Post Comments

const Models = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/Models.vue');

const Agents = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Users/Agents.vue');

  //Analytics
  const AdminAnalytics = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Analytics/Admin.vue');

  const AgentAnalytics = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Analytics/Agent.vue');

  const ModelAnalytics = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Analytics/Model.vue');

  const AdminstrationPage = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Admin/AdministrationPage.vue');


let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },

    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: { requiresAuthentication: false },
      component: User
    },
    {
      path: '/logout',
      name: 'logout',
      component: Login,
      meta: { requiresAuthentication: false }
    }

  ]
};


const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Home'
  },

  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/home',
    name: 'Dashboard layout',
    children: [
      {
        path: 'admin/analytics',
        name: 'Analytics',
        meta: {requiresAuthentication: true , requiresAdmin: true},
        components: { default: AdminAnalytics }
      }
      ,
      {
        path: 'agent/statistics',
        name: 'Statistics',
        meta: {requiresAuthentication: true, requiresAgent: true},
        components: { default: AgentAnalytics }
      }
      ,
      {
        path: 'model/statistics',
        name: 'Statistics',
        meta: {requiresAuthentication: true,requiresModel: true},
        components: { default: ModelAnalytics }
      },
      {
        path: 'home',
        name: 'Home',
        components: { default: Home }
      },
      {
        path: 'explore',
        name: 'Explore',
        components: { default: Explore }
      },
      {
        path: 'locations',
        name: 'Locations',
        components: { default: Locations }
      },
      {
        path: 'live-feed',
        name: 'Live Feed',
        meta: {requiresAuthentication: true},
        components: { default: LiveFeed }
      },
      {
        path: 'chat',
        name: 'Chat',
        meta: { requiresAuthentication: true },
        components: { default: Chat }
      },
      {
        path: 'create',
        name: 'Create',
        meta: { requiresAuthentication: true, requiresModel: true},
        components: { default: Create } //Create Post
      },
      {
        path: 'categories',
        name: 'Category',
        meta: { requiresAuthentication: true , requiresAdmin: true},
        components: { default: Categories }
      },
      {
        path: 'model/:id',
        name: 'Model',
        // meta: {requiresAuthentication: true},
        components: { default: ModelProfile }
      },
      {
        path: 'modelUid/:user_id',
        name: 'My Profile',
        meta: {requiresAuthentication: true},
        components: { default: ModelProfileByUserId }
      },
      {
        path: 'account/stars',
        name: 'Account Settings',
        meta: { requiresAuthentication: true , requiresModel: true},
        components: { default: AccountSettingsStars }
      },
      {
        path: 'account/agents',
        name: 'Account Settings',
        meta: { requiresAuthentication: true , requiresAgent: true},
        components: { default: AccountSettingsAgents }
      },
      {
        path: 'account/subscribers',
        name: 'My Profile',
        meta: { requiresAuthentication: true , requiresCustomer: true},
        components: { default: AccountSettingsSubscribers }
      },
      {
        path: 'agents/manageModels',
        name: 'Manage Models',
        meta: { requiresAuthentication: true, requiresAgent: true},
        components: { default: ManageModels }
      },
      {
        path: 'agents/addModel',
        name: 'Add Model',
        meta: { requiresAuthentication: true },
        components: { default: CreateModel , requiresAgent: true}
      },
      {
        path: 'admin/manageAllModels',
        name: 'Manage All Models',
        meta: { requiresAuthentication: true , requiresAdmin: true},
        components: { default: ManageAllModels }
      },
      {
        path: 'admin/manageAgents',
        name: 'Manage Agents',
        meta: { requiresAuthentication: true , requiresAdmin: true},
        components: { default: ManageAgents }
      },
      {
        path: 'agent/:user_id/:agent_id',
        name: 'Agent',
        meta: { requiresAuthentication: false , requiresAgent: true},
        components: { default: AgentProfile }
      },
      {
        path: 'user/storyFullView/:model_id',
        name: 'StoryFullView',
        meta: { requiresAuthentication: true },
        components: { default: StoryFullView }
      },
      {
        path: 'post/:post_id',
        name: 'PostComments',
        meta: { requiresAuthentication: true },
        components: { default: PostComments }
      },
      {
        path: 'models/:title/:criteria',
        name: 'Models',
        meta: { requiresAuthentication: false },
        components: { default: Models }
      },
      {
        path: 'agents/:title/:criteria',
        name: 'Agents',
        meta: { requiresAuthentication: true },
        components: { default: Agents }
      }
      ,{
        path: 'admin/management',
        name: 'Admin Page',
        meta: { requiresAuthentication: true , requiresAdmin: true},
        components: { default: AdminstrationPage }
      }

    ]
  },
  { path: '*', component: NotFound }
];

export default routes;

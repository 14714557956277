<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <div class="search-bar input-group d-flex">
        <!--
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
        -->
        <div>
          <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal" @click="searchModalVisible = true">
            <i class="tim-icons icon-zoom-split"></i>
          </button>
        </div>
        <div>
          <router-link class="nav-item" tag="li" :to="'/modelUid/' + this.user_id"
            v-if="isLoggedIn && this.user_type == 3">
            <a class="nav-link">
              <i class="tim-icons icon-single-02 "></i>
            </a>
          </router-link>
        </div>

        <a class="nav-link" @click="logout()" style="cursor:pointer;" v-if="isLoggedIn">
          <i class="tim-icons icon-button-power"></i>
        </a>



        <!-- You can choose types of search input -->
      </div>
      <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false" :show-close="true">
        <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup"
          placeholder="SEARCH" />
      </modal>
      <ul class="navbar-nav">

        <router-link class="nav-item" tag="li" to="/register" v-if="!isLoggedIn">
          <a class="nav-link">
            <i class="tim-icons icon-laptop"></i> Register
          </a>
        </router-link>

        <router-link class="nav-item" tag="li" to="/login" v-if="!isLoggedIn">
          <a class="nav-link">
            <i class="tim-icons icon-single-02"></i> Login
          </a>
        </router-link>

      </ul>
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" title-classes="nav-link" class="nav-item">

      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import { api_url, datumMixin } from "../../env"

export default {
  mixins: [datumMixin],
  components: {
    SidebarToggleButton,
    BaseNav,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      user_type: 0,
      user_id: 0,
      isLoggedIn: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };

      this.$http.get(api_url + '/logout', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return false
        })
        .then(response => {
          if (!response) {
            this.alertNotification("bottom", "right", "warning", "An error occurred")
            return
          }
          else {
            if (response["status"] != "true") {
              this.alertNotification("top", "right", "danger", response["message"])
              return
            } else {
              //store data to store
              this.alertNotification("top", "right", "success", response["message"])
              localStorage.clear()
              this.$router.push({ name: 'home' })
              location.reload()
              return
            }
          }
        })



    

    },

  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.isLoggedIn = true;
      this.user_type = localStorage.getItem('user_type')
      this.user_id = localStorage.getItem('user_id')
    }

  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}

.btn {
  /* padding: 0%; */
  width: 100%;
}
</style>
